.mainDiv{
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:5px solid #0d0d0d;
    border-radius: 10px;
    position: relative;
}

.mainDiv:hover .backgroundImg {
    opacity: 1;
}

.backgroundImg {
    position: relative;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

}

.iconImg {
    width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
    .mainDiv{
        width: 25%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border:2px solid #0d0d0d;
        border-radius: 10px;
        position: relative;
    }
}
