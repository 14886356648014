.mainDiv {
    padding: 20px;
    position: fixed;
    left: 0;
    top: 15px;
    height: 60px;
    width: 100%;
}

.amLink {
    text-decoration: none;
    color: gray;
    font-family: 'Orbitron', sans-serif;
    font-size: 45px;
    margin-left: 80px;
}

.amLink:hover {
    color: white;
}

@media screen and (max-width: 500px) {
    .mainDiv {
        padding: 0;
        position: relative;
        left: 0;
        top: 0;
        height: 60px;
        width: 100%;
        text-align: center;
    }
    .amLink {
        text-decoration: none;
        color: gray;
        font-family: 'Orbitron', sans-serif;
        font-size: 45px;
        margin-left: 0;
    }
}