@import url(https://fonts.googleapis.com/css2?family=Orbitron&family=Ubuntu+Mono&display=swap);
* {
  box-sizing: border-box;
}
body {
  min-height: 100vh;
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0d0d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.App_mainDiv__3VEfg{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.App_mainDiv__3VEfg > div{
    display: flex;
    max-width: 60%;
}
.App_gridDiv__3pUwM {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 500px) {
    .App_mainDiv__3VEfg {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .App_mainDiv__3VEfg > div{
        flex-direction: column;
        display: flex;
        max-width: none;
    }
    .App_gridDiv__3pUwM {
        display: flex;
        flex-direction: column;
    }
}
.Header_mainDiv__3a2pb {
    padding: 20px;
    position: fixed;
    left: 0;
    top: 15px;
    height: 60px;
    width: 100%;
}

.Header_amLink__2Jscp {
    text-decoration: none;
    color: gray;
    font-family: 'Orbitron', sans-serif;
    font-size: 45px;
    margin-left: 80px;
}

.Header_amLink__2Jscp:hover {
    color: white;
}

@media screen and (max-width: 500px) {
    .Header_mainDiv__3a2pb {
        padding: 0;
        position: relative;
        left: 0;
        top: 0;
        height: 60px;
        width: 100%;
        text-align: center;
    }
    .Header_amLink__2Jscp {
        text-decoration: none;
        color: gray;
        font-family: 'Orbitron', sans-serif;
        font-size: 45px;
        margin-left: 0;
    }
}
.Grid_mainDiv__H72jc{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.Grid_mainDiv__H72jc > * {
    width: 50%;
}

@media screen and (max-width: 500px) {
    .Grid_mainDiv__H72jc{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
.GridItem_mainDiv__3SiFK{
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border:5px solid #0d0d0d;
    border-radius: 10px;
    position: relative;
}

.GridItem_mainDiv__3SiFK:hover .GridItem_backgroundImg__b4ekM {
    opacity: 1;
}

.GridItem_backgroundImg__b4ekM {
    position: relative;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

}

.GridItem_iconImg__2FuDa {
    width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
    .GridItem_mainDiv__3SiFK{
        width: 25%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border:2px solid #0d0d0d;
        border-radius: 10px;
        position: relative;
    }
}

.ItemView_main-div__jnOue{
    width: 100%;
    height: 100%;
}

.ImageView_img__1TaGw {
    width:500px;
    position: relative;
    border:5px solid #0d0d0d;
    border-radius: 10px;
}


@media screen and (max-width: 500px) {
    .ImageView_img__1TaGw {
        width:100%;
        position: relative;
        border:5px solid #0d0d0d;
        border-radius: 10px;
        max-height: 70vh;
        object-fit: cover;
        object-position: top;
    }
}

.Footer_mainDiv__28BcR {
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 5px;
    height: 60px;
    width: 100%;
}

.Footer_footerList__13OCQ {
    display: flex;
    list-style: none;
    justify-content: flex-end;
}

.Footer_footerItem__198R6 {
    margin-right: 50px;
}

.Footer_links__wOI9L {
    text-decoration: none;
    color: gray;
    font-family: 'Ubuntu Mono', monospace;
}

.Footer_links__wOI9L:hover {
    color: white;
}
.Code_mainDiv__20ULL {

}

h1 {
    color: white;
}
.About_mainDiv__282wE {

}

h1 {
    color: white;
}
.Contact_mainDiv__1hrhj {

}

h1 {
    color: white;
}

.Contact_nameDiv__3kmRv {

}
.Contact_fName__3SpV0 {

}
.Contact_sName__16qrV {

}
.Contact_emailDiv__341lu {

}
.Contact_email__1bBOI {

}
.Contact_phoneDiv__2kHdj {

}
.Contact_phone__14JNn {

}
.Contact_messageDiv__25pUM {

}
.Contact_message__3rwam {

}
.Contact_buttonDiv__3JWCJ {

}
.Contact_button__1vk9L {

}
.Blog_mainDiv__1ibsS {

}

h1 {
    color: white;
}
