.mainDiv{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.mainDiv > * {
    width: 50%;
}

@media screen and (max-width: 500px) {
    .mainDiv{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}