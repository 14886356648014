.img {
    width:500px;
    position: relative;
    border:5px solid #0d0d0d;
    border-radius: 10px;
}


@media screen and (max-width: 500px) {
    .img {
        width:100%;
        position: relative;
        border:5px solid #0d0d0d;
        border-radius: 10px;
        max-height: 70vh;
        object-fit: cover;
        object-position: top;
    }
}
