.mainDiv{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mainDiv > div{
    display: flex;
    max-width: 60%;
}
.gridDiv {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 500px) {
    .mainDiv {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
    .mainDiv > div{
        flex-direction: column;
        display: flex;
        max-width: none;
    }
    .gridDiv {
        display: flex;
        flex-direction: column;
    }
}